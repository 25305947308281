<template>
  <div id="detail">
    <div class="Breadcrumb">
      <b-breadcrumb>
        <b-breadcrumb-item href="#/">
          <b-icon icon="house" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
          首页
        </b-breadcrumb-item>
        <b-breadcrumb-item href="#/news">产品动态</b-breadcrumb-item>
        <b-breadcrumb-item active>新闻详情</b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <div class="part1">
      <div class="title">{{detail.title}}</div>
      <div class="time">发表于 {{detail.created_at}}</div>
      <div class="content" v-html="detail.body"></div>
      <div class="foot">
        <div v-if="prev" @click="toDetail(prev.id)"><span>上一篇：</span>{{prev.title}}</div>
        <div v-if="next" @click="toDetail(next.id)"><span>下一篇：</span>{{next.title}}</div>
      </div>
    </div>
    <myhead :background="'dark'"></myhead>
    <foot></foot>
    <right></right>
  </div>
</template>

<script>
  import myhead from '@/components/head.vue'
  import foot from '@/components/foot.vue'
  import right from '@/components/right.vue'
  export default {
    name: "newsDetail",
    data() {
      return {
        id: this.$route.query ? this.$route.query.id : '',
        detail: {},
        next: null,
        prev: null
      }
    },
    components: {
      foot,
      right,
      myhead
    },
    mounted() {
      this.handleDetail()
    },
    methods: {
      handleDetail() {
        let params = {
          has_simple_page: 1
        }
        this.$axios.get(`${this.$Tools.Conts.domain}official/news/${this.id}`, {
          params
        }).then(res => {
          if (res.data.code === 0) {
            this.detail = res.data.data.present
            this.next = res.data.data.next;
            this.prev = res.data.data.prev;
          } else {
            this.$toast.warn({
              message: res.data.message,
              timeOut: 2000
            })
          }
        })
      },
      toDetail(id) {
        console.log(1)
        this.$router.push({
          name: "NewsDetail",
          query: {
            id: id
          }
        })
      }
    },
    watch: {
      //vue跳转同一页面强制刷新,watch监听$route
      '$route': function () {
        this.id = this.$route.query ? this.$route.query.id : '';
        this.handleDetail()
      }
    }
  }
</script>

<style lang="less" scoped>
  #detail {
    background: rgb(247, 247, 247);
    padding-top: 5rem;

    .Breadcrumb {
      margin: 0 15px;

      .breadcrumb {
        background: transparent;
        padding: 0;
        font-size: 0.8rem;

        a {
          color: #2b85e4;
        }
      }
    }

    .part1 {
      background: white;
      border-radius: 6px;
      margin: 0 15px 2rem;

      .title {
        font-size: 1.2rem;
        padding: 0.8rem 1rem;
      }

      .time {
        font-size: 0.8rem;
        color: #777777;
        padding: 0rem 1rem;
      }

      .content {
        padding: 1rem 1rem;
        border-bottom: 1px solid #c4c4c4;

        /deep/ video {
          width: 100%;
        }

        /deep/ p {
          margin-bottom: 0.2rem;
        }
      }

      .foot {
        padding: 1rem 1rem;

        &>div {
          font-size: 0.7rem;
          cursor: pointer;
          opacity: 0.8;

          &:first-child {
            margin-bottom: 0.5rem;
          }

          &:hover {
            opacity: 1;
          }
        }

        span {
          color: #007bff;
        }
      }
    }
  }

  @media screen and (min-width: 576px) {
    #detail {
      background: rgb(247, 247, 247);
      padding-top: 5rem;

      .Breadcrumb {
        width: 70%;
        margin: auto;

        .breadcrumb {
          background: transparent;
          padding: 0.75rem 0;
          font-size: 1rem;

          a {
            color: #2b85e4;
          }
        }
      }

      .part1 {
        background: white;
        border-radius: 6px;

        width: 70%;
        margin: 0 auto 2rem;

        .title {
          font-size: 1.2rem;
          padding: 0.8rem 2rem;
        }

        .time {
          font-size: 0.8rem;
          color: #777777;
          padding: 0rem 2rem;
        }

        .content {
          padding: 1rem 2rem;
          border-bottom: 1px solid #c4c4c4;

          /deep/ video {
            width: 50%;
          }

          /deep/ p {
            margin-bottom: 0.2rem;
          }
        }

        .foot {
          padding: 1rem 2rem;

          &>div {
            font-size: 0.9rem;
            cursor: pointer;
            opacity: 0.8;

            &:first-child {
              margin-bottom: 0.5rem;
            }

            &:hover {
              opacity: 1;
            }
          }

          span {
            color: #007bff;
          }
        }
      }
    }
  }
</style>